import mixpanel from "mixpanel-browser";

export const UserModule = {
  namespaced: true,

  state: {
    first_name: "",
    last_name: "",
    username: "",
    avatar: "",
    has_unpaid_fees: 0,
    id: null,
    uuid: null,
    accepted_terms_at: null,
    roles: [],
  },

  actions: {
    /**
     *
     * @param [commit]
     * @param {Object} payload
     */
    updateUser({ commit, rootState }, payload) {
      let {
        data: { attributes },
      } = payload;
      if (attributes.first_name) {
        commit("SET_FIRST_NAME", attributes.first_name);
      }
      if (attributes.last_name) {
        commit("SET_LAST_NAME", attributes.last_name);
      }
      if (attributes?.has_unpaid_fees) {
        commit("SET_LIMITED_GUARDIAN", attributes?.has_unpaid_fees);
      }
      if (attributes.avatar) {
        commit("SET_AVATAR", attributes.avatar);
      } else {
        commit("SET_AVATAR", "https://via.placeholder.com/300x300.png?text=Placeholder");
      }
      if (payload.data.id) {
        commit("SET_ID", payload.data.id);
      }
      if (attributes.email) {
        commit("SET_USERNAME", attributes.email);
      }
      if (attributes.uuid) {
        commit("SET_UUID", attributes.uuid);
      }
      if (attributes.roles) {
        commit("SET_ROLES", attributes.roles);
      }
      if (attributes.accepted_terms_at) {
        commit("SET_ACCEPTED_TERMS_AT", attributes.accepted_terms_at);
      }
      mixpanel.identify(attributes.uuid);
      mixpanel.people.set({
        "First Name": attributes.first_name,
        "Last Name": attributes.last_name,
        $email: attributes.email,
        $name: attributes.first_name + " " + attributes.last_name,
        Roles: attributes.roles.map(role => role.attributes.name),
        Id: payload.data.id,
        "Tenant Id": rootState.auth.tenantId,
      });
    },
  },

  mutations: {
    SET_FIRST_NAME(state, payload) {
      state.first_name = payload;
    },
    SET_LAST_NAME(state, payload) {
      state.last_name = payload;
    },
    SET_AVATAR(state, payload) {
      state.avatar = payload;
    },
    SET_LIMITED_GUARDIAN(state, payload) {
      state.has_unpaid_fees = payload;
    },
    SET_ID(state, payload) {
      state.id = payload;
    },
    SET_UUID(state, payload) {
      state.uuid = payload;
    },
    SET_USERNAME(state, payload) {
      state.username = payload;
    },
    SET_ROLES(state, payload) {
      state.roles = payload;
    },
    SET_ACCEPTED_TERMS_AT(state, payload) {
      state.accepted_terms_at = payload;
    },
  },
};
