import User from "@/models/sis/User";
import Store from "@/store";
import UserPermission from "@/models/sis/UserPermission";
import Setting from "@/models/sis/Setting";
// import Router from "@/router";
import VueCookies from "vue-cookies";
import axios from "axios";

export default class GenexUtils {
  static setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  static objectiveProgressStatusClass(status) {
    const statuses = {
      I: "info",
      P: "primary",
      D: "warning",
      M: "success",
      E: "danger",
    };

    return `has-background-${statuses[status] ?? "grey"}`;
  }

  static makeStorageSizeHumanReadable(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }
    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + " " + units[u];
  }

  static fileTypesToExtensions(arg) {
    let result = [];
    if (arg?.length) {
      for (const argElement of arg) {
        result.push(...argElement.extensions);
      }
    }
    return result;
  }

  static saveAuthDetails(response) {
    let permissionData = [];
    response.data.data.attributes.permissions.forEach(permission => {
      permissionData.push({
        id: permission.id,
        name: permission.attributes.name,
      });
    });
    UserPermission.insert({
      data: permissionData,
    }).then();
    if (!Store.getters["tenant/isCentral"]) {
      Setting.FetchAll({ page: 1, limit: 999 });
    }

    Store.dispatch("user/updateUser", {
      ...response.data,
      ...response.data.attributes,
    }).then();

    if (response.data.data.attributes.roles.length === 1) {
      if (response.data.data.attributes.roles.some(role => role.attributes.name === "learner")) {
        Store.dispatch("auth/updateType", "learner").then();
      }
      if (response.data.data.attributes.roles.some(role => role.attributes.name.includes("guardian"))) {
        Store.dispatch("auth/updateType", "guardian").then();
      }
      // if (response.data.data.attributes.roles.some(role => role.attributes.name.includes('educator'))) {
      //     Store.dispatch("auth/updateType", 'educator').then();
      // }
      if (response.data.data.attributes.roles.some(role => role.attributes.name === "super admin")) {
        Store.dispatch("auth/updateSwitch", true).then();
      }
    }
    if (response.data.data.attributes.roles.length > 1) {
      if (response.data.data.attributes.roles.some(role => role.attributes.name === "guardian")) {
        Store.dispatch("auth/updateSwitch", true).then();
      }
    }

    (Store.state.auth.type === "guardian" || Store.state.auth.canSwitch) && !Store.getters["tenant/isCentral"]
      ? User.CheckGuardianHomeAccess()
          .then(() => {
            Store.state.auth.canAccessGuardianHome = true;
            // Store.dispatch('auth/updateGuardianHomeAccess', true).then()
          })
          .catch(() => {
            Store.state.auth.canAccessGuardianHome = false;

            // Store.dispatch('auth/updateGuardianHomeAccess', false).then()
          })
      : Promise.resolve();
  }

  static logoutAndPurgeUser() {
    Store.dispatch("auth/updateAuthenticated", false).then();
    Store.state.auth.accessToken = null;
    VueCookies.remove("access_token");
    VueCookies.remove("tenant_id");
    VueCookies.remove("tenant_uuid");
    VueCookies.remove("tenant_storage");
    VueCookies.remove("tenant_school_name");
    VueCookies.remove("tenant_is_white_labeled");
    VueCookies.remove("SecondRefresh");
    axios.interceptors.request.eject(Store.state.auth.auth_interceptor);
    axios.interceptors.request.eject(Store.state.auth.tenant_interceptor);

    // Router.replace({ name: "login" }).then(() => {
    //   Store.dispatch("toast/createToast", {
    //     message: "Please login to continue",
    //   }).then();
    // });
  }
}
