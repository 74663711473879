<template>
  <nav
    v-show="isNavBarVisible"
    id="navbar-main"
    aria-label="main navigation"
    class="navbar is-fixed-top is-absolute"
    role="navigation">
    <div class="navbar-brand">
      <div class="navbar-item is-hidden-tablet px-0 py-0">
        <a
          aria-expanded="false"
          aria-label="menu"
          class="navbar-burger is-hidden-tablet"
          data-target="sideMenuButton"
          role="button"
          @click.prevent="menuToggleMobile">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <a
        :class="{ 'is-active': isMenuNavBarActive }"
        aria-label="menu"
        class="navbar-item mr-2 is-hidden-desktop"
        @click="menuNavBarToggle">
        <b-icon icon="more-vertical" />
      </a>
    </div>
    <div
      :class="{ 'is-active': isMenuNavBarActive, 'above-overlay': overlay }"
      class="navbar-menu fadeIn animated faster">
      <div class="navbar-end">
        <nav-bar-menu class="has-user-avatar">
          <div class="is-user-name mr-2">
            <span>Welcome, {{ user.first_name }}</span>
          </div>
          <div class="is-user-avatar">
            <img :alt="user.first_name + ' ' + user.last_name" :src="user.avatar" />
          </div>
          <div slot="dropdown" :class="{ 'is-boxed': !$store.getters['size/isTouch'] }" class="navbar-dropdown">
            <a class="navbar-item is-flex is-align-content-center" @click="openProfile">
              <b-icon icon="user" custom-size="default"></b-icon>
              <span>My Profile</span>
            </a>
            <a class="navbar-item is-flex is-align-content-center" @click="openChat">
              <b-icon icon="messages" custom-size="default"></b-icon>
              <span>Chat</span>
            </a>
            <a
              v-if="$store.state.auth.canSwitch && !isCentral"
              class="navbar-item is-flex is-align-content-center"
              @click="toggleView()">
              <b-icon v-if="$store.state.auth.type === 'guardian'" icon="user" custom-size="default"></b-icon>
              <b-icon v-else icon="profile-user" custom-size="default"></b-icon>
              <span
                >{{ $store.state.auth.type === "guardian" ? "Staff" : "Guardian" }}
                View
              </span>
            </a>
          </div>
        </nav-bar-menu>
        <nav-bar-menu :hide-open-on-mobile="true">
          <b-icon icon="more-vertical"></b-icon>
          <div
            slot="dropdown"
            :class="{ 'is-boxed': !$store.getters['size/isTouch'] }"
            class="navbar-dropdown end-navbar-dropdown">
            <a
              v-if="type === 'staff' || type === 'guardian'"
              class="navbar-item is-flex is-align-content-center"
              title="Documentation"
              @click="openDocumentation(null)">
              <b-icon icon="documentation" custom-size="default" />
              <span>Info Hub</span>
            </a>
            <a
              v-if="type === 'staff' || type === 'guardian'"
              :class="{ 'is-active': isAsideRightVisible }"
              class="navbar-item is-flex is-align-content-center"
              title="Documentation"
              @click.prevent="docsToggle">
              <b-icon :icon="docsMenuToggleIcon" custom-size="default" />
              <span>Docs</span>
            </a>
            <a class="navbar-item is-flex is-align-content-center" title="Log out" @click="openWhatsNewModal">
              <b-icon custom-size="default" icon="notification" />
              <span>What's New</span>
            </a>
            <a
              v-if="helpdeskVisible"
              class="navbar-item is-flex is-align-content-center"
              title="Helpdesk"
              @click="openHelpdesk">
              <b-icon icon="message-question" custom-size="default" />
              <span>Helpdesk Chat</span>
            </a>
            <a
              v-if="!isCentral"
              class="navbar-item is-flex is-align-content-center"
              title="Support"
              @click="launchSupportTicketWindow">
              <b-icon icon="ticket" custom-size="default" />
              <span>Support Tickets</span>
            </a>
            <hr class="my-1" />
            <a class="navbar-item is-flex is-align-content-center" title="Log out" @click="logout">
              <b-icon custom-size="default" icon="logout" />
              <span>Log out</span>
            </a>
            <LoginToLMS />
          </div>
        </nav-bar-menu>
      </div>
    </div>
    <b-loading
      ref="pageOverlay"
      :active="overlay || (isAsideMobileExpanded && $store.getters['size/isTouch']) || isAsideRightVisible"
      :can-cancel="false"
      :is-full-page="true"
      class="page-overlay"
      @click="hitOverlay">
      <p @click="hitOverlay"></p>
    </b-loading>
  </nav>
</template>

<script>
  import NavBarMenu from "@/components/layout/NavBarMenu";
  import { mapGetters, mapState } from "vuex";
  import Campus from "@/models/sis/Campus";
  import DocumentationWindow from "@/components/documents/DocumentationWindow";
  import TenantSupportModal from "@/components/support/TenantSupportModal";
  import SupportTicket from "@/models/sis/SupportTicket";
  // import Learner from "@/models/Learner";
  import User from "@/models/sis/User";
  //import { parseMap } from "@/assets/docs/docsMap";
  import HelpdeskUser from "@/models/sis/HelpdeskUser";
  import HelpdeskCampusSelectForm from "@/components/chat/HelpdeskCampusSelectForm.vue";
  import GenexUtils from "@/utils/GenexUtils";
  import LoginToLMS from "@/components/layout/LoginToLMS.vue";

  export default {
    name: "NavBar",
    components: {
      LoginToLMS,
      NavBarMenu,
    },
    data() {
      return {
        isMenuNavBarActive: false,
        learner: Object,
        docs: {},
      };
    },
    watch: {
      supportWindowOpen(newVal) {
        if (newVal === true) {
          this.$buefy.modal.open({
            parent: this,
            props: {
              inModal: true,
              tenantMode: true,
            },
            component: TenantSupportModal,
            hasModalCard: true,
            fullScreen: false,
            trapFocus: true,
            canCancel: ["x"],
            onCancel: () => {
              this.$store.state.support.supportWindowOpen = false;
              this.removeQuery(["ticket_id"]);
            },
            events: {
              close: () => {
                this.$store.state.support.supportWindowOpen = false;
                this.removeQuery(["ticket_id"]);
              },
            },
          });
        }
      },
    },
    computed: {
      helpdeskVisible() {
        return Campus.query().where("has_helpdesk", 1).count() > 0;
      },
      menuNavBarToggleIcon() {
        return this.isMenuNavBarActive ? "close" : "more-vertical";
      },
      menuToggleIcon() {
        return this.isAsideExpanded ? "minimize" : "maximize";
      },
      menuToggleMobileIcon() {
        return this.isAsideMobileExpanded ? "backburger" : "forwardburger";
      },
      docsMenuToggleIcon() {
        return this.isAsideRightVisible ? "forwardburger" : "backburger";
      },
      toggleTooltip() {
        return this.isAsideExpanded ? "Collapse" : "Expand";
      },
      ...mapGetters("tenant", ["isCentral"]),
      ...mapState("menu", ["isNavBarVisible", "isAsideExpanded", "isAsideMobileExpanded", "isAsideRightVisible"]),
      user() {
        return User.query().whereId(this.id).exists()
          ? User.query().whereId(this.id).first() ?? {
              first_name: "",
              last_name: "",
              avatar: "",
            }
          : {
              first_name: this.first_name,
              last_name: this.last_name,
              avatar: this.avatar,
            };
      },
      ...mapState("user", ["last_name", "first_name", "avatar", "id"]),
      ...mapState("auth", ["type"]),
      ...mapState("loader", ["overlay"]),
      ...mapState("support", ["supportWindowOpen"]),
      ...mapState("release", ["releaseWindowOpen"]),
    },
    methods: {
      openHelpdesk() {
        this.$store.dispatch("loader/show");
        HelpdeskUser.FriendWithHelpdesk(this.$store.state.user.uuid)
          .then(async () => {
            if (Campus.query().where("has_helpdesk", 1).count() > 1) {
              this.$buefy.modal.open({
                parent: this,
                component: HelpdeskCampusSelectForm,
                fullScreen: false,
                hasModalCard: true,
                trapFocus: true,
                canCancel: ["button", "x", "escape"],

                events: {
                  selectedCampus: async campus_id => {
                    // eslint-disable-next-line no-undef
                    await CometChatWidget.chatWithUser(Campus.find(campus_id)?.helpdesk_uuid);
                    // eslint-disable-next-line no-undef
                    CometChatWidget.openOrCloseChat(true);
                  },
                },
              });
            } else {
              // eslint-disable-next-line no-undef
              await CometChatWidget.chatWithUser(Campus.query().where("has_helpdesk", 1).first()?.helpdesk_uuid);
              // eslint-disable-next-line no-undef
              CometChatWidget.openOrCloseChat(true);
            }
          })
          .catch(err => {
            this.handleError(err);
          })
          .finally(() => {
            this.$store.dispatch("loader/hide");
          });
      },
      hitOverlay() {},
      openDocumentation() {
        this.track("Info Hub modal", {});
        this.setQuery({ openDocumentation: "yes" });

        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
          },
          component: DocumentationWindow,
          hasModalCard: true,
          fullScreen: false,
          trapFocus: true,
          canCancel: ["x"],
          onCancel: () => {
            this.removeQuery(["openDocumentation"]);
            this.removeQuery(["documentSlug"]);
          },
        });
      },
      toggleView() {
        if (this.$store.state.auth.type === "guardian") {
          this.$store.dispatch("auth/updateType", "staff");
          this.$router.push("/");
        } else if (this.$store.state.auth.type === "staff") {
          this.$store.dispatch("auth/updateType", "guardian");
          this.$router.push("/g/");
        }
      },
      openProfile() {
        switch (this.type) {
          case "staff":
            this.$router.push(`/staff/edit/${this.id}`);
            break;
          case "educator":
            this.$router.push(`/staff/edit/${this.id}`);
            break;
          case "guardian":
            this.$router.push("/g/my-profile");
        }
      },
      openChat() {
        switch (this.type) {
          case "staff":
            this.$router.push(`/chat`);
            break;
          case "educator":
            this.$router.push(`/chat`);
            break;
          case "guardian":
            this.$router.push("/g/chat");
        }
      },
      menuToggle() {
        this.$store.dispatch("menu/asideStateToggle");
      },
      menuToggleMobile() {
        this.$store.dispatch("menu/asideMobileStateToggle");
      },
      forceClose() {
        this.isMenuNavBarActive = false;
        this.$store.dispatch("loader/hideOverlay");

        window.removeEventListener("click", this.forceClose);
      },
      menuNavBarToggle() {
        this.isMenuNavBarActive = !this.isMenuNavBarActive;
        if (this.isMenuNavBarActive) {
          this.$store.dispatch("loader/showOverlay");
          setTimeout(() => {
            window.addEventListener("click", this.forceClose);
          }, 50);
        } else {
          this.$store.dispatch("loader/hideOverlay");
        }
      },
      docsToggle() {
        document.body.style.position = "fixed";
        document.body.style.top = "0";
        this.$store.dispatch("menu/asideRightToggle");
        this.$store.state.documentation.active_doc_document_path = this.parseMap(this.$route);
      },
      parseMap(route) {
        let mapKey;
        if (route.query.tab) {
          let tabName = route.query.tab;
          mapKey = route.meta.tabDocPaths[tabName];
          return mapKey;
        } else {
          mapKey = route.meta.docPath;
          return mapKey;
        }
      },
      logout() {
        this.resetTracking();
        GenexUtils.logoutAndPurgeUser();
        let slug = this.$cookies.get("tenant_slug") || null;
        if (slug) {
          window.location.href = `/login/${slug}`;
        } else {
          window.location.href = "/login";
        }
      },
      launchSupportTicketWindow() {
        this.track("Support modal", {});

        this.$store.dispatch("support/openSupportWindow", !this.$store.state.support.editingTicket);
      },
      cookieRemoval(key) {
        this.$cookies.remove(key);
      },
      openWhatsNewModal() {
        this.track("What's New modal", {});
        this.$store.dispatch("release/openReleaseWindow");
      },
    },
    beforeDestroy() {
      window.removeEventListener("click", this.forceClose);
    },
    mounted() {
      if (!this.$store.state.isAsideExpanded) {
        this.$store.dispatch("menu/asideStateToggle");
      }

      if (this.$store.state.auth.type === "staff" && !this.isCentral) {
        Campus.FetchAll({ page: 1, limit: 100 }).then(response => {
          if (response.response.data.data.length > 0) {
            let campusId;

            if (this.$store.state.campus.selected_campus_id !== null) {
              campusId = parseInt(this.$store.state.campus.selected_campus_id);
            } else {
              campusId = response.response.data.data[0].id;
            }
            if (!Campus.query().whereId(parseInt(campusId)).exists()) {
              campusId = response.response.data.data[0].id;
            }
            this.$store.dispatch("campus/selectCampus", campusId);
            Campus.FetchById(campusId);
          }
        });
      } else if (!this.isCentral) {
        Campus.FetchAll({ page: 1, limit: 100 }, {}, []).then(() => {
          let campusId;
          if (this.$store.state.campus.selected_campus_id !== null) {
            campusId = parseInt(this.$store.state.campus.selected_campus_id);
          } else {
            campusId = Campus.query().first().id;
          }
          this.$store.dispatch("campus/selectCampus", campusId);
        });
      }
      setTimeout(() => {
        if (this.$route.query.openDocumentation === "yes") {
          this.openDocumentation();
        }
        // if (!this.isCentral) {
        if (this.$route.query.ticket_id) {
          SupportTicket.FetchById(this.$route.query.ticket_id, ["user"]).then(({ entities: { tickets = null } }) => {
            this.$store.dispatch("support/startEdit", tickets[0]);
          });
        }
        // }
      }, 500);
    },
  };
</script>
<style scoped>
  #nav-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
