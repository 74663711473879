import { GenexSISModel } from "@/models/sis/GenexSISModel";
import ClassGroup from "@/models/sis/ClassGroup";
import LessonPlan from "@/models/sis/LessonPlan";
import User from "@/models/sis/User";
import Subject from "@/models/sis/Subject";

/**
 * A Lesson Plan
 * @property {number} id
 * @property {number} lesson_plan_id  - The id of the lesson plan associated with this lesson
 * @property {number} status_id  - The status of the lesson 1 planned 2 complete 3 unplanned
 * @property {number} course_scope_id - The id of the class associated with this lesson plan
 * @property {Object} courseScope - The courseScope this lesson plan belongs to
 * @property {number} campus_id - The id of the campus this lesson plan belongs to
 * @property {Object} campus - the campus this lesson plan belongs to
 * @property {string} starter_activity - The starter activity of the lesson
 * @property {string} engagement - The engagement of the lesson
 * @property {string} checkpoint - The checkpoint of the lesson
 * @property {string} resources - The resources string of the lesson
 * @property {Array.<number>} objective_ids - the ids of the objectives in this lesson plan
 * @property {Array.<Object>} objectives - The the objectives in this lesson plan
 */
export default class Lesson extends GenexSISModel {
  static entity = "lessons";

  static fields() {
    return {
      id: this.attr(null),
      lesson_plan_id: this.attr(null),
      class_group_id: this.attr(null),
      class_group: this.belongsTo(ClassGroup, "class_group_id"),
      lesson_plan: this.belongsTo(LessonPlan, "lesson_plan_id"),
      subject_id: this.attr(null),
      subject: this.belongsTo(Subject, "subject_id"),
      learner_ids: this.attr(() => []),
      learners: this.hasManyBy(User, "learner_ids"),
      status_id: this.attr(null),
      date: this.attr(null),
      note: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
    };
  }

  /**
   * Returns all Lessons
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} query
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} All the Lessons
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.GenexAxiosGet("Lesson.FetchAll", `/lessons`, {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(object => {
          if (relationships.includes("lesson_plan")) {
            if (object.attributes.lesson_plan !== null) {
              Object.assign(object.attributes.lesson_plan, object.attributes.lesson_plan.attributes);
            }
          }
          if (relationships.includes("class_group")) {
            Object.assign(object.attributes.class_group, object.attributes.class_group.attributes);
          }
          if (relationships.includes("campus")) {
            Object.assign(object.attributes.campus, object.attributes.campus.attributes);
          }
          if (relationships.includes("learners")) {
            object.attributes.learners.map(learner => {
              Object.assign(learner, learner.attributes);
            });
          }
          if (relationships.includes("user")) {
            Object.assign(object.attributes.user, object.attributes.user.attributes);
          }
          if (relationships.includes("subject")) {
            Object.assign(object.attributes.subject, object.attributes.subject.attributes);
          }

          return { ...object, ...object.attributes };
        });
      },
    });
  }

  /**
   * Returns a lesson
   * @function
   * @param {number} id - The id of the Lesson
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Lesson
   */

  static FetchById(id, relationships = []) {
    return this.GenexAxiosGet("Lesson.FetchById", `/lessons/${id}`, {
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("lesson_plan")) {
          if (data.attributes.lesson_plan !== null) {
            Object.assign(data.attributes.lesson_plan, data.attributes.lesson_plan.attributes);

            if (relationships.includes("lesson_plan.objectives")) {
              if (Object.prototype.hasOwnProperty.call(data.attributes.lesson_plan, "objectives")) {
                data.attributes.lesson_plan.objectives.map(item => {
                  Object.assign(item, item.attributes);
                });
              }
            }
          }
        }
        if (relationships.includes("class_group")) {
          Object.assign(data.attributes.class_group, data.attributes.class_group.attributes);
        }
        if (relationships.includes("campus")) {
          Object.assign(data.attributes.campus, data.attributes.campus.attributes);
        }
        if (relationships.includes("learners")) {
          data.attributes.learners.map(learner => {
            Object.assign(learner, learner.attributes);
          });
        }

        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Returns the count of previously planned lessons
   * @function
   * @returns {Promise<Response>} the planned count
   */

  static PreviousPlannedCount(query = {}) {
    return this.GenexAxiosGet("Lesson.PreviousPlannedCount", `/lessons/previous-planned-count`, {
      params: {
        ...(query !== {} ? query : {}),
      },
      save: false,
    });
  }

  /**
   * Processes the previously planned lessons
   * @function
   * @param {number} strategy - int 1 = move to this week, 2 = unallocate, 3 = nothing
   * @param {number} subject_id - the id of the associated subject
   * @returns {Promise<Response>} the planned count
   */

  static ProcessPlanned(strategy, subject_id) {
    return this.GenexAxiosPost(
      "Lesson.ProcessPlanned",
      `/lessons/process-previous-planned-count`,
      {
        strategy: strategy,
        subject_id: subject_id,
      },
      {
        save: false,
      },
    );
  }

  /**
   * Store a new lesson
   * @function
   * @param {Object} lesson - The lesson object
   * @returns {Promise<Response>} - The newly created lesson
   */

  static Store(lesson) {
    return this.GenexAxiosPost("Lesson.Store", `/lessons`, lesson, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a lesson
   * @function
   * @param {Object} lesson - The lesson object
   * @returns {Promise<Response>} - The updated lesson
   */

  static Update(lesson) {
    return this.GenexAxiosPatch("Lesson.Update", `/lessons/${lesson.id}`, lesson, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete a lesson
   * @function
   * @param {number} id - The id of the lesson
   */

  static Delete(id) {
    return this.GenexAxiosDelete("Lesson.Delete", `/lessons/${id}`, {
      delete: id,
    });
  }
}
