<template>
  <div :class="{ 'un-card': unCard }" class="card" style="overflow: visible">
    <template v-if="title">
      <header
        :class="{
          'has-background-white': !transparent,
          'has-background-transparent': transparent,
          'is-clickable': clickable,
        }"
        class="card-header"
        @click.prevent="headerClick">
        <p class="card-header-title is-capitalized has-text-primary">
          <b-icon v-if="icon" :icon="icon" custom-size="default" type="is-primary" />
          {{ title }}
        </p>
        <router-link v-if="moreLink" :to="moreLink" class="card-header-icon has-text-primary" @click.stop
          >{{ moreLinkText }}
        </router-link>
        <a
          v-if="headerIcon"
          aria-label="more options"
          class="card-header-icon has-text-primary"
          href="#"
          @click.prevent="headerIconClick">
          <b-icon :icon="headerIcon" />
        </a>
        <a
          v-if="secondHeaderIcon"
          aria-label="more options"
          class="card-header-icon has-text-primary"
          href="#"
          @click.prevent="headerIconClick">
          <b-icon :icon="secondHeaderIcon" />
        </a>
      </header>
      <hr :class="{ 'mt-1 mb-1': compact }" class="card-divider" />
    </template>
    <div
      :class="{
        'p-2': compact,
        'has-background-white': !transparent,
        'has-background-transparent': transparent,
      }"
      class="card-content">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: "CardComponent",
    props: {
      unCard: {
        type: Boolean,
        default: false,
      },
      clickable: {
        type: Boolean,
        default: false,
      },
      transparent: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: null,
      },
      icon: {
        type: String,
        default: null,
      },
      headerIcon: {
        type: String,
        default: null,
      },
      moreLink: {
        type: String,
        default: null,
      },
      moreLinkText: {
        type: String,
        default: null,
      },
      secondHeaderIcon: {
        type: String,
        default: null,
      },
      compact: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      headerIconClick() {
        this.$emit("header-icon-click");
      },
      headerClick() {
        this.$emit("header-click");
      },
    },
  };
</script>

<style scoped>
  .un-card .card-header-title {
    padding: 0.75rem 0;
  }

  .un-card .card-content {
    padding: 0;
  }
</style>
