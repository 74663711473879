<template>
  <a
    v-if="hasPermission('access lms')"
    class="navbar-item is-flex is-align-content-center"
    title="Log into LMS"
    @click="LoginIntoLMS">
    <b-icon custom-size="default" icon="logout" />
    <span>Log into LMS</span>
    <GenexMiniLoader v-if="loading" class="ml-2" />
  </a>
</template>

<script>
  import User from "@/models/sis/User";
  import GenexMiniLoader from "@/components/global/GenexMiniLoader.vue";
  import { normalize } from "@/utils/data";

  export default {
    name: "LoginToLMS",
    components: { GenexMiniLoader },
    data() {
      return {
        loading: false,
        localUser: null,
      };
    },
    mounted() {
      User.FetchById(this.$store.state.user.id, ["roles"]).then(response => {
        this.localUser = normalize(response.response.data.data);
      });
    },
    methods: {
      LoginIntoLMS() {
        this.loading = true;
        User.LoginIntoLMS(this.$store.state.user.id).then(response => {
          this.loading = false;
          if (response.response.data) {
            const auth = response.response.data;
            window.open(
              process.env.VUE_APP_LMS_FRONTEND_URL +
                "external-login" +
                "?ExternalLoginTenantId=" +
                this.$store.state.auth.tenantId +
                "&ExternalLoginLMSTenantId=" +
                auth.lms_tenant_id +
                "&ExternalLoginToken=" +
                auth.access_token +
                "&ExternalLoginUserId=" +
                this.localUser.lms_user_id + // needs to be lms usr id
                "&ExternalLoginRefreshToken=" +
                auth.refresh_token,
              "_blank",
            );
          }
        });
      },
    },
  };
</script>

<style scoped></style>
