var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",class:{ 'un-card': _vm.unCard },staticStyle:{"overflow":"visible"}},[(_vm.title)?[_c('header',{staticClass:"card-header",class:{
        'has-background-white': !_vm.transparent,
        'has-background-transparent': _vm.transparent,
        'is-clickable': _vm.clickable,
      },on:{"click":function($event){$event.preventDefault();return _vm.headerClick.apply(null, arguments)}}},[_c('p',{staticClass:"card-header-title is-capitalized has-text-primary"},[(_vm.icon)?_c('b-icon',{attrs:{"icon":_vm.icon,"custom-size":"default","type":"is-primary"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")],1),(_vm.moreLink)?_c('router-link',{staticClass:"card-header-icon has-text-primary",attrs:{"to":_vm.moreLink},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.moreLinkText)+" ")]):_vm._e(),(_vm.headerIcon)?_c('a',{staticClass:"card-header-icon has-text-primary",attrs:{"aria-label":"more options","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.headerIconClick.apply(null, arguments)}}},[_c('b-icon',{attrs:{"icon":_vm.headerIcon}})],1):_vm._e(),(_vm.secondHeaderIcon)?_c('a',{staticClass:"card-header-icon has-text-primary",attrs:{"aria-label":"more options","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.headerIconClick.apply(null, arguments)}}},[_c('b-icon',{attrs:{"icon":_vm.secondHeaderIcon}})],1):_vm._e()],1),_c('hr',{staticClass:"card-divider",class:{ 'mt-1 mb-1': _vm.compact }})]:_vm._e(),_c('div',{staticClass:"card-content",class:{
      'p-2': _vm.compact,
      'has-background-white': !_vm.transparent,
      'has-background-transparent': _vm.transparent,
    }},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }